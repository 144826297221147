import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { GssoRoutingModule } from './gsso-routing.module';
import { UiModule } from '@virtual-trials-workspace/shared-ui';
import { TranslocoModule } from '@ngneat/transloco';
import { ContentSheetComponent } from './content-sheet/content-sheet.component';
import { GssoService } from './http/gsso.service';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

@NgModule({ declarations: [LoginComponent, ContentSheetComponent], imports: [CommonModule,
        GssoRoutingModule,
        UiModule,
        TranslocoModule], providers: [
        GssoService,
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class GssoModule {}
